import axios from "axios";
import { API_WEB_URLS } from "constants/constAPI";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { BePositive } from "store/functions";

// Function to map Ledger array to Select options
const mapLedgerToSelectOptions = (ledger) =>
  (ledger || []).map(item => ({
    value: item.Id,
    label: item.Name,
  }));

function TableRowsVoucher({ rowsData, add, LedgerA, LedgerR, edit, del }) {
  const [ledgerBalances, setLedgerBalances] = useState({});

  const fetchLedgerBalance = async (ledgerId, type, index) => {
    if (!ledgerId) return; // Skip if ledgerId is missing

    try {
      const formData = new FormData();
      formData.append("Id", ledgerId);

      const response = await axios.post(
        `${API_WEB_URLS.BASE}GetBalance/0/token`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        }
      );
  
      const balance = BePositive(response.data.data.response[0].Balance);
      setLedgerBalances((prevBalances) => ({
        ...prevBalances,
        [`${type}_${index}`]: balance,
      }));
    } catch (error) {
      console.error("Error fetching ledger balance:", error);
    }
  };

  useEffect(() => {
    rowsData.forEach((data, index) => {
      const {
        F_LedgerMasterCrA,
        F_LedgerMasterDrA,
        F_LedgerMasterCrR,
        F_LedgerMasterDrR
      } = data;
      console.log(F_LedgerMasterCrA,F_LedgerMasterDrA,F_LedgerMasterCrR,F_LedgerMasterDrR);
      fetchLedgerBalance(F_LedgerMasterCrA, "CrA", index);
      fetchLedgerBalance(F_LedgerMasterDrA, "DrA", index);
      fetchLedgerBalance(F_LedgerMasterCrR, "CrR", index);
      fetchLedgerBalance(F_LedgerMasterDrR, "DrR", index);
    });
  }, [rowsData]);

  // Map Ledger arrays to Select options
  const ledgerAOptions = mapLedgerToSelectOptions(LedgerA);
  const ledgerROptions = mapLedgerToSelectOptions(LedgerR);

  const renderSelectField = (options, value, onChange, balance) => (
    <>
      <Select
        options={options}
        value={options.find(option => option.value === value) || null}
        onChange={onChange}
        styles={{
          container: (provided) => ({
            ...provided,
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            marginBottom : balance ? null : '28px'
          }),
          singleValue: (provided) => ({
            ...provided,
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          menu: (provided) => ({
            ...provided,
            position: "absolute",
            zIndex: 9999,
            top: "100%",
            maxHeight: "200px",
            overflowY: "auto",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        menuPortalTarget={document.body}
      />
      {balance && <div style={{ marginTop: '5px' }}>
        <small>Balance: {balance}</small>
      </div>}
    </>
  );

  return rowsData.map((data, index) => {
    const {
      Id,
      F_LedgerMasterCrA,
      F_LedgerMasterDrA,
      F_LedgerMasterCrR,
      F_LedgerMasterDrR,
      Amount,
      Remarks,
    } = data;

    const crBalanceA = ledgerBalances[`CrA_${index}`] || "Fetching...";
    const drBalanceA = ledgerBalances[`DrA_${index}`] || "Fetching...";
    const crBalanceR = ledgerBalances[`CrR_${index}`] || "Fetching...";
    const drBalanceR = ledgerBalances[`DrR_${index}`] || "Fetching...";

    return (
      <tr key={index}>
        <td style={{ width: "300px" }}>
          {renderSelectField(ledgerAOptions, F_LedgerMasterCrA, (evnt) => edit(index, 'F_LedgerMasterCrA', evnt.value), crBalanceA)}
        </td>

        <td style={{ width: "300px" }}>
          {renderSelectField(ledgerAOptions, F_LedgerMasterDrA, (evnt) => edit(index, 'F_LedgerMasterDrA', evnt.value), drBalanceA)}
        </td>

        <td style={{ width: "300px" }}>
          {renderSelectField(ledgerROptions, F_LedgerMasterCrR, (evnt) => edit(index, 'F_LedgerMasterCrR', evnt.value))}
        </td>

        <td style={{ width: "300px" }}>
          {renderSelectField(ledgerROptions, F_LedgerMasterDrR, (evnt) => edit(index, 'F_LedgerMasterDrR', evnt.value))}
        </td>

        <td>
          <input
            style={{ width: "100px",marginBottom:'28px' }}
            onChange={evnt => edit(index, 'Amount', evnt.target.value)}
            value={Amount}
            name="Amount"
            type="number"
            className="form-control"
          />
        </td>

        <td>
          <input
            style={{ width: "100px",marginBottom:'28px' }}
            onChange={evnt => edit(index, 'Remarks', evnt.target.value)}
            value={Remarks}
            name="Remarks"
            type="text"
            className="form-control"
          />
        </td>

        <td>
          <button
          style={{marginBottom:'28px' }}
            className="btn btn-outline-danger"
            type="button"
            onClick={() => del(Id, index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

export default TableRowsVoucher;
