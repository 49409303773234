import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios"; // For API calls
import { API_WEB_URLS } from "constants/constAPI";
import { BePositive } from "store/functions";

// Function to map Ledger array to Select options
const mapLedgerToSelectOptions = (ledger) =>
  (ledger || []).map(item => ({
    value: item.Id,
    label: item.Name,
  }));

function TableRowsPly({ rowsData, add, Ledger, edit, del, F_VoucherTypeMaster }) {
  const [ledgerBalances, setLedgerBalances] = useState({});

  // Map Ledger array to Select options, handling null or undefined
  const ledgerOptions = mapLedgerToSelectOptions(Ledger);

  // Function to fetch ledger balance by ledgerId
  const fetchLedgerBalance = async (ledgerId, type, index) => {
    if (ledgerId) {
      try {
        
        const formData = new FormData();
        formData.append("Id", ledgerId);
  
        
        const response = await axios.post(
          `${API_WEB_URLS.BASE}GetBalance/0/token`, 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          }
        );
  
        const balance = BePositive(response.data.data.response[0].Balance)
          console.log(balance)
        // Update the balance in the state
        setLedgerBalances((prevBalances) => ({
          ...prevBalances,
          [`${type}_${index}`]: balance,
        }));
      } catch (error) {
        console.error("Error fetching ledger balance:", error);
      }
    }
  };
  

  // Fetch the balance for all F_LedgerMasterCr and F_LedgerMasterDr on component mount
  useEffect(() => {
    rowsData.forEach((data, index) => {
      if (data.F_LedgerMasterCr) {
        fetchLedgerBalance(data.F_LedgerMasterCr, "Cr", index);
      }
      if (data.F_LedgerMasterDr) {
        fetchLedgerBalance(data.F_LedgerMasterDr, "Dr", index);
      }
    });
  }, [rowsData]); // Re-run when rowsData changes

  return rowsData.map((data, index) => {
    const { Id, F_LedgerMasterCr, F_LedgerMasterDr, Amount } = data;

    // Find default options
    const defaultCrAOption = ledgerOptions.find(option => option.value === F_LedgerMasterCr) || null;
    const defaultDrAOption = ledgerOptions.find(option => option.value === F_LedgerMasterDr) || null;

    // Get the balance for Cr and Dr from state
    const crBalance = ledgerBalances[`Cr_${index}`] || "Fetching...";
    const drBalance = ledgerBalances[`Dr_${index}`] || "Fetching...";

    return (
      <tr key={index}>
        <td
          style={{
            width: "500px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerOptions}
            onChange={evnt => edit(index, 'F_LedgerMasterCr', evnt ? evnt.value : null)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto",
                overflowY: "auto",
                whiteSpace: "normal",
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%",
                maxHeight: "200px",
                overflowY: "auto",
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultCrAOption}
            menuPortalTarget={document.body}
          />
          {/* Display Credit Ledger Balance */}
          <div style={{ marginTop: '5px' }}>
            <small>Balance: {crBalance}</small>
          </div>
        </td>

        <td
          style={{
            width: "500px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerOptions}
            onChange={evnt => edit(index, 'F_LedgerMasterDr', evnt ? evnt.value : null)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto",
                overflowY: "auto",
                whiteSpace: "normal",
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%",
                maxHeight: "200px",
                overflowY: "auto",
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultDrAOption}
            menuPortalTarget={document.body}
          />
          {/* Display Debit Ledger Balance */}
          <div style={{ marginTop: '5px' }}>
            <small>Balance: {drBalance}</small>
          </div>
        </td>

        <td>
          <input
            style={{ width: "200px",marginBottom:'28px' }}
            onChange={evnt => edit(index, 'Amount', evnt.target.value)}
            value={Amount}
            name="Amount"
            type="number"
            className="form-control"
          />
        </td>

        <td>
          <button
          style={{marginBottom:'28px' }}
            type="button"
            className="btn btn-outline-success"
            onClick={add}
          >
            +
          </button>
        </td>
        <td>
          <button
          style={{marginBottom:'28px' }}
            className="btn btn-outline-danger"
            type="button"
            onClick={() => del(Id, index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

export default TableRowsPly;
